.input-fields {
  margin-bottom: 5px;
  vertical-align: middle;
}

.query-button {
  margin-top: -3px;
  height: 32px;
  padding-top: 5px;
}

.margin-top-10 {
  margin-top:  10px;
}

.margin-left-15{
  margin-left: 15px;
}

.kore-field-width{
  width: 330px;
}

.jira-field-width{
  width: 353px;
}

.jira-input-field-width{
  width: 200px;
}

.modal-input-field-width{
  width: 100%;
}

.modal-text-area-field{
  resize: vertical;
}

.list-unstyled{
  list-style-type: none;
}

.device-history{
  width: 400px;
  height: 320px;
}

.header-text-center{
  text-align: center;
  margin-left: -12px;
}

.select-width{
  width: 250px;
}

.border-box{
  border: solid black 2px 2px 2px 2px;
}

.margin-bottom-5{
  margin-bottom: 5px;
}



.index-0-padding-bottom{
  padding-bottom: 10px;
}

.loading-container-area{
  height: 400px;
  width: 600px;
  align-content: center;
  vertical-align: middle;
}

.small-loading-container{
  height: 50px;
  width: 50px;
  display: inline-block;
  vertical-align: middle;
}

.small-loading-container-area{
  height: 50px;
  width: 50px;
  vertical-align: top;
  display: inline-block;
  position: "";
}

.footer-top-margin{
  margin-top:80px;
}

.footer{
  position: absolute;
  bottom:0;
  width:90%;
  height:60px;
}

.selector-margin-bottom{
  margin-bottom: 10px;
}
.selector-front{
  z-index: 10;
}
 
.widget-box{
  width: 500px;
  /* height: 300px; */
}

.itemStepWidth{
  width: 880px;
}