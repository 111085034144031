* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.error-text-incident {
    color: red;
}

.custom-container {
    display: flex;
    flex-flow: row wrap;
}

.filter-incident {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
}

.filter-incident .filter-subtext {
    font-size: 12px;
    color: rgb(121, 121, 121);
}

.incident-button-container {
    padding-bottom: 5px;
}

.page-selector {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.page-selector .number-selector {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline
}
.page-selector .number-selector > * {
    margin-right: 5px;
}

.page-selector .selection-icon {
    padding: 1px 8px;
    background-color: rgb(255,255,255);
    border: 1px solid rgb(161,161,161);
    border-radius: 100%;
}
.page-selector .selection-icon:hover {
    cursor: pointer;
    background-color: rgb(211,211,211);
}

.page-selector .quick-selector {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
}
.page-selector .quick-selector > * {
    margin-right: 5px;
}

.page-selector .quick-selector .selection-icon {
    padding: 2.5px;
    background-color: rgb(255,255,255);
    border: 1px solid rgb(161,161,161);
    border-radius: 100%;
}
.page-selector .quick-selector .selection-icon:hover {
    cursor: pointer;
    background-color: rgb(211,211,211);
}

.incident-history-form {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 2.5px;
}
.incident-history-form > * {
    margin-right: 10px;
}

.incident-history {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
}
.incident-history > * {
    margin-bottom: 15px;
}

.incident-history .container {
    width: 100%;
    padding: 0;
    margin-top: 7.5px;
}

.incident-history .container .date-title {
    font-size: 20px;
    margin-bottom: 5px;
}

.incident-history .container .date-incidents {
    display: flex;
    flex-flow: column wrap;
}
.incident-history .container .date-incidents > * {
    margin-bottom: 10px;
}

.incident-history .container .date-incidents .incident-card {
    display: flex;
    flex-flow: column wrap;
    background-color: rgb(255,255,255);
    border: 1px solid rgb(211,211,211);
    border-radius: 10px;
    padding: 12.5px;
    filter: drop-shadow(0px 0px 2.5px rgb(161,161,161));
    font-size: 13px;
}
.incident-history .container .date-incidents .incident-card > * {
    margin-bottom: 10px;
    margin-left: 5px
}