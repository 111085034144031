* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.subscription-modal .header {
    background-color: rgb(235,235,235);
    color: rgb(0,0,0);
    padding: 26.5px 12.5px 16.5px 28.5px
}

.subscription-modal .header .save {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: baseline;
    margin-top: 15px;
}

.subscription-modal .products-display > * {
    margin-bottom: 5px;
}