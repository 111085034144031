* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.border-test {
    border: 5px solid red;
}

.full-width {
    width: 100%;
}

.fit-content {
    width: fit-content;
}

.error-text {
    color: red;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.active-container .notActive {
    display: flex;
    flex-flow: row wrap;
    border: 1px solid black;
    border-radius: 10px;
    padding: 12.5px;
    padding-top: 7.5px;
    margin-top: 20px;
}

.active-container .active {
    display: flex;
    flex-flow: column wrap;
    border: 0;
}

.active-container .active .header {
    margin: 0px;
    border: 0px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    background-color: rgb(255,255,255);
}

.active-container .header .content {
    text-align: left;
    display: flex;
    flex-flow: column wrap;
    font-size: 20px;
}

.active-container .incidents {
    display: flex;
    flex-flow: column wrap;
}
.active-container .incidents > * {
    margin-bottom: 15px;
}

.active-container .incidents .content {
    display: flex;
    flex-flow: column wrap;
    background-color: rgb(255,255,255);
    font-size: 13px;
    border: 1px solid rgb(211,211,211);
    border-radius: 10px;
    padding: 12.5px;
    filter: drop-shadow(0px 0px 2.5px rgb(161,161,161));
}

.active-container .incidents .content .header {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
}

.active-container .incidents .content .header .button-title {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 0px;
    background-color: rgb(255,255,255);
    border: 0;
}

.active-container .incidents .content-expanded {
    display: flex;
    flex-flow: column wrap;
    margin-top: 5px;
    padding-left: 22.5px;
}
.active-container .incidents .content-expanded > * {
    margin-bottom: 10px;
}

.product-groups-container .productGroup {
    margin-bottom: 15px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(211,211,211);
    border-radius: 10px;
    overflow: hidden;
    filter: drop-shadow(0px 0px 2.5px rgb(161,161,161))
}
.product-groups-container .productGroup .product-button {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    border: 0;
    padding: 12.5px;
    background-color: rgb(255,255,255);
}
.product-groups-container .productGroup .product-button .header {
    margin: 0px;
    border: 0px;
    display: flex;
    flex-direction: row wrap;
    align-items: baseline;
}
.product-groups-container .product-content .product-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    background: rgb(255,255,255);
    border-top: 1px solid rgb(211,211,211);
}
.product-groups-container .product-content .product-container .content {
    display: flex;
    flex-flow: column wrap;
}

.status-container .icon-container {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
    justify-content: space-between;
}

.status-container .icon-container .content {
    flex: 0 1 320px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.status-container .group-card {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}

.dashboard-button-container {
    padding-bottom: 5px;
}

.active-container .incident-card {
    border: 1px solid black;
    display: flex;
    flex-flow: column wrap;
}

.form-container > * {
    margin-bottom: 15px;
}

.form-container .productsAffected .header {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    border: 0;
    background-color: rgb(255,255,255);
    padding: 0;
}

.form-container .productsAffected .productsDisplay {
    display: flex;
    flex-flow: row wrap;
}

.statusTable {
    width: 100%;
    border-collapse: collapse;
}
.statusTable td, th {
    border: 1px solid rgb(221, 221, 221);
    text-align: center;
    font-weight: normal;
}
.statusTable td:first-child, th:first-child {
    text-align: left;
}

.detailed-incident-content {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
}

.detailed-incident-content #detailedIncidentUpdate {
    flex: 1 0 220px;
}
.detailed-incident-content #detailedIncidentUpdateButton {
    background-color: rgb(221, 221, 221);
}

.detailed-incident-table {
    width: 100%;
    border-collapse: collapse;
}
.detailed-incident-table td, th {
    border: 1px solid rgb(221, 221, 221);
}

.datetime-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}

.tool-tip-container {
    display: flex;
    flex-flow: row wrap;
    align-items: baseline;
}

.form-button-container {
    margin: 15px 0px;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
}

.required-text::after {
    content: ' *';
    color: red;
}

.history-date-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
}